import React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import TransferredNft from "./TransferredNft";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useEffect } from "react";
import { socket } from "../../../wallet/helpers";

import {
    cleanTxnHashArr,
    removeFromSelectedNFTList,
    setNFTSetToggler,
    setTxnStatus,
} from "../../../store/reducers/generalSlice";
import "./SuccessModal.css";
import Tooltip from "../AccountModal/Tooltip";
import { chainsConfig, CHAIN_INFO } from "../../values";
import { setQRCodeModal } from "../../Wallet/TONWallet/tonStore";

export default function SuccessModal() {
    const dispatch = useDispatch();
    const from = useSelector((state) => state.general.from);
    const to = useSelector((state) => state.general.to);

    const algorandAccount = useSelector(
        (state) => state.general.algorandAccount
    );
    const elrondAccount = useSelector((state) => state.general.elrondAccount);
    const tronWallet = useSelector((state) => state.general.tronWallet);
    const account = useSelector((state) => state.general.account);
    const receiver = useSelector((state) => state.general.receiver);
    const txnHashArr = useSelector((state) => state.general.txnHashArr);
    const selectedNFTList = useSelector(
        (state) => state.general.selectedNFTList
    );
    const testnet = useSelector((state) => state.general.testNet);
    const secretAccount = useSelector((state) => state.general.secretAccount);
    const tezosAccount = useSelector((state) => state.general.tezosAccount);
    const tonAccount = useSelector((state) => state.general.tonAccount);

    const address =
        tonAccount ||
        account ||
        algorandAccount ||
        elrondAccount ||
        tronWallet ||
        secretAccount ||
        tezosAccount ||
        "";

    const handleClose = () => {
        selectedNFTList.forEach((nft) => {
            const { txn } = nft;
            if (txn) dispatch(removeFromSelectedNFTList(nft));
        });
        dispatch(cleanTxnHashArr());
        dispatch(setNFTSetToggler());
        dispatch(setQRCodeModal(false));
    };

    const getSubstringValue = () => {
        if (window.innerWidth <= 320) return 3;
        else if (window.innerWidth <= 375) return 6;
        else return false;
    };

    const getTX = () => {
        if (txnHashArr && txnHashArr.length > 0) {
            if (typeof txnHashArr === "object" && !Array.isArray(txnHashArr)) {
                return txnHashArr[0].hash.toString();
            } else if (Array.isArray(txnHashArr)) {
                if (typeof txnHashArr[0] === "object") {
                    return txnHashArr[0].hash.toString();
                } else {
                    return txnHashArr[0].toString();
                }
            } else {
                return txnHashArr;
            }
        } else {
            return "wrong tx";
        }
    };

    const getExplorer = () => {
        return !testnet
            ? `${CHAIN_INFO[from?.text]?.blockExplorerUrls}${address}`
            : `${CHAIN_INFO[from?.text]?.testBlockExplorerUrls}${address}`;
    };

    useEffect(() => {
        //alert(`success modal ${getTX().toString()}:${socket.connected}`);
        socket.on("incomingEvent", async (e) => {
            /* if (e.fromChain === "24" || e.fromChainName === "SECRET") {
        alert(e.fromHash);
      }*/
            dispatch(setTxnStatus(e));
            console.log("Incoming Event: ", e);
        });
        socket.on("updateEvent", async (e) => {
            /*if (e.fromChain === "24" || e.fromChainName === "SECRET") {
        alert(e.toHash);
      }*/
            dispatch(setTxnStatus(e));
            console.log("Update Event: ", e);
        });
        return () => {
            if (socket) {
                socket.off("incomingEvent");
                socket.off("updateEvent");
            }
        };
    }, []);

    return (
        <>
            <span onClick={handleClose} className="success-modal-close">
                <div className="close-modal"></div>
            </span>
            <Modal.Header className="border-0">
                <Modal.Title>
                    <div className="custom-success-modal__title">
                        Bridging Results
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="success-info-list">
                {false && (
                    <div className="success-info-box">
                        <div className="success-info-item">
                            <div className="info-item-label">Date</div>
                            <span>{moment().format("YYYY-MM-DD hh:mm")}</span>
                        </div>
                        <div className="success-info-item">
                            <div className="info-item-label">Txn Hash</div>
                            <CopyToClipboard text={getTX() || "No tx"}>
                                <a
                                    href={
                                        testnet
                                            ? `${chainsConfig[from?.key]
                                                  ?.testTx + getTX()}`
                                            : `${chainsConfig[from?.key]?.tx +
                                                  getTX()}`
                                    }
                                    target="_blank"
                                    className="success-hash"
                                    rel="noreferrer"
                                >
                                    {getTX()
                                        ? `${getTX().substring(
                                              0,
                                              getSubstringValue() || 10
                                          )}...${getTX().substring(
                                              getTX().length - 6
                                          )}`
                                        : ""}
                                    <Tooltip />
                                </a>
                            </CopyToClipboard>
                        </div>
                    </div>
                )}
                <div className="success-info-box">
                    <div className="success-info-item">
                        <div className="info-item-label">Date</div>
                        <div className="info-item-chain">
                            {moment(txnHashArr[0]?.trxDate).format(
                                "YYYY-M-DD HH:mm"
                            ) || moment.format("YYYY-M-DD HH:mm")}
                        </div>
                    </div>
                    <div className="success-info-item">
                        <div className="info-item-label">Sent From</div>
                        <div className="info-item-chain">
                            <img src={from?.image?.src} alt={from?.text} />
                            {from?.text === "xDai" ? "Gnosis" : from?.text}
                        </div>
                    </div>
                    <div className="success-info-item">
                        <div className="info-item-label">Departure Address</div>
                        <a
                            href={getExplorer() || "#"}
                            className="success-hash"
                            target="_blank"
                            rel="noreferrer"
                        >
                            {address
                                ? `${address.substring(
                                      0,
                                      getSubstringValue() || 10
                                  )}...${address.substring(address.length - 6)}`
                                : ""}
                        </a>
                    </div>
                    <div className="success-info-item">
                        <div className="info-item-label">Sent To</div>
                        <div className="info-item-chain">
                            <img src={to?.image?.src} alt={to?.text} />
                            {to?.text === "xDai" ? "Gnosis" : to?.text}
                        </div>
                    </div>
                    <div className="success-info-item">
                        <div className="info-item-label">
                            Destination Address
                        </div>
                        <a
                            className="success-hash"
                            href={`${
                                CHAIN_INFO[to?.text]?.blockExplorerUrls
                            }${receiver}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {receiver
                                ? `${receiver.substring(
                                      0,
                                      getSubstringValue() || 10
                                  )}...${receiver.substring(
                                      receiver.length - 6
                                  )}`
                                : "test"}
                        </a>
                    </div>
                </div>
                <div className="success-info-box">
                    <div className="info-item-label">
                        Sent NFT / {selectedNFTList?.length || "8"}
                    </div>
                    <div className="success-nft-info">
                        {selectedNFTList.length
                            ? selectedNFTList.map((nft, index) => (
                                  <TransferredNft
                                      key={`index-${index}-nft-success`}
                                      nft={nft}
                                      testnet={testnet}
                                  />
                              ))
                            : ""}
                    </div>
                </div>
            </Modal.Body>
        </>
    );
}
