import React from "react";

export default function NotWhiteListed() {
  return (
    <div className="image__wrapper not-whitelisted">
      <div className="not-whitelisted__wrapper">
        <span className="not-whitelisted__text">NFT is not Whitelisted</span>
        {/* <a className='not-whitelisted__button' href='https://t.me/XP_NETWORK_Bridge_Support_Bot?start=startwithxpbot' target="_blank" rel="noreferrer">Tech support</a> */}
      </div>
    </div>
  );
}
