import { createSlice } from "@reduxjs/toolkit";

const initialState = {

};

const transferResultsSlice = createSlice({
    name: "transferResults",
    initialState,
    reducers: {

    }
})

// export const {

// }

export default transferResultsSlice.reducer;

